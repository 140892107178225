window._ = require('lodash');

/**
 * axios
 */

window.axios = require('axios').default;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * vue.js
 */

window.Vue = require('vue');

/**
 * boostrap vue
 */

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

/**
 * Registro de componentes globales de Vue
 */
Vue.component('climbs', require('../js/com/map/charts/climbs.vue').default);
