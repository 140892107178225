<template>
    <table v-if="data.ascents" class="table">
        <thead class="thead-dark">
            <tr>
                <th class="text-center">{{titleCategory}}</th>
                <th class="text-center">{{titleAscents}}</th>
                <th class="text-center">{{titleSummits}}</th>
                <th class="text-center">%</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th class="text-center">1</th>
                <td class="text-center">{{data.ascents[1]}}</td>
                <td class="text-center">{{data.mountains[1]}}</td>
                <td class="text-center">{{data.ascents[1]|percentage(data.mountains[1])}}</td>
            </tr>
            <tr>
                <th class="text-center">2</th>
                <td class="text-center">{{data.ascents[2]}}</td>
                <td class="text-center">{{data.mountains[2]}}</td>
                <td class="text-center">{{data.ascents[2]|percentage(data.mountains[2])}}</td>
            </tr>
            <tr>
                <th class="text-center">3</th>
                <td class="text-center">{{data.ascents[3]}}</td>
                <td class="text-center">{{data.mountains[3]}}</td>
                <td class="text-center">{{data.ascents[3]|percentage(data.mountains[3])}}</td>
            </tr>
            <tr>
                <th class="text-center fw-bold text-uppercase">{{titleTotal}}</th>
                <td class="text-center">{{data.ascents[0]}}</td>
                <td class="text-center">{{data.mountains[0]}}</td>
                <td class="text-center fw-bold">{{data.ascents[0]|percentage(data.mountains[0])}}</td>
            </tr>
        </tbody>
        </table>
</template>
<script>

    export default {
        props: ['data', 'title-category', 'title-ascents',  'title-summits', 'title-total'],
        /**
         * Cuando la instancia está montada
         *
         */
        mounted () {
           //
        },
        /**
         * Los Filtros
         * 
         */
        filters: {
            /**
             * Repersenta un porcentaje
             * 
             * @param {Number} value        El valor
             * @param {Number} total        El total
             * 
             * @return {String}             El porcentaje debidamente formateado
             *
             */
            percentage: function (value, total) {
                return total != 0 ? `${(value * 100 / total).toFixed(1).replace('.', ',')} %` : '---';
            },
        }
    }
</script>
